<template>
    <div class="page-table scrollable only-y mainDiv mb-30">
        <div class="card-base baslikBg animated fadeInUp">
            Cari Listesi          
            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.yeniKaydet")' :open-delay="500" placement="top" v-if="yetkiTuru != '3'">
                    <el-button v-on:click="routeKurum('Cari Tanımla')" size="mini" type="text" icon="el-icon-plus"></el-button>
                </el-tooltip>

                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" style="margin-left: 15px !important" type="text" icon="el-icon-refresh"></el-button>
                </el-tooltip>
            </div>
        </div>

        <div class="page-header card-base card-shadow--medium animated fadeInUp" v-loading="kurumLoading || searchLoading"
        :element-loading-text='kurumLoading ? "Cari Listesi Getiriliyor. Lütfen Bekleyiniz." : "Cari Aranıyor. Lütfen Bekleyiniz."'
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)">
            <el-row class="mt-0 mb-10">
                <el-col :lg="12" :md="12" :sm="24" :xs="24">
                  <!--  <div>
                        <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9" text-color="gray" :disabled="searchLoading">
                            <el-badge :value="12" class="item baddge"></el-badge>
                                <el-radio-button label="1">
                                    <i class="mdi mdi-cards-outline mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                    <transition name="slide-fade">
                                        <label v-if="kurumDurumu ==='1'">Gelen Cariler</label>
                                    </transition>
                                </el-radio-button>                            
                       
                                <el-radio-button label="2">
                                    <i class="mdi mdi-cards-outline mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                    <transition name="slide-fade">
                                        <label v-if="kurumDurumu ==='2'">Giden Cariler</label>
                                    </transition>
                                </el-radio-button>
                        </el-radio-group>
                        </div> -->
                        <div>
                            <el-radio-group v-on:change="handleChange($event)" v-model="radio1" size="medium">
                                <el-badge :value="gelenCari" class="item cari"><el-radio-button label="1" class="gelen">Gelen Cariler</el-radio-button></el-badge>
                                <el-badge :value="gidenCari" class="item cari"><el-radio-button label="2" class="giden">Giden Cariler</el-radio-button></el-badge>
                            </el-radio-group>
                        </div>

                         
                </el-col>
               <el-col :lg="12" :md="12" :sm="24" :xs="24" v-if="yetkiTuru != '3'">
                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                        <ul class="islemler" >                            
                            <li>
                                <i class="mdi mdi-pen" style="color: #f7ba2a"></i> 
                                <span>Cari Güncelle</span>
                            </li>
                        </ul>
                    </el-col>
                </el-col>
            </el-row>
            <el-table v-loading="searchLoading" border stripe :data="kurumListe" style="width: 100%;" 
            :element-loading-text='"Cari Aranıyor. Lütfen bekleyin."'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
               <!--
                <el-table-column>
                    <template slot="header">
                    </template>
                    <el-table-column
                        :label='$t("src.views.apps.genel.sira")'
                        type="index"
                        :index="indexMethod"
                        width="70">
                    </el-table-column>
                </el-table-column>
                
                <el-table-column>
                    <template slot="header">
                    </template>
                    <el-table-column
                        prop="kurumID"
                        label='Sıra No'
                        width="90">
                    </el-table-column>
                </el-table-column>
                -->
                <el-table-column>
                    <template slot="header" slot-scope="props">
                        <el-input clearable v-on:clear="kurumAra" placeholder='Kurum Ara' v-model="kurumAdiAra" v-debounce:500ms="kurumAra" size="mini"></el-input>
                    </template>
                    <el-table-column 
                        prop="kurumAdi"
                        label="Cari Adı"
                        width="auto">
                        <template slot-scope="props">
                            <div>
                                {{props.row.kurumAdi}}
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>
          

                <el-table-column fixed="right" v-if="yetkiTuru != '3'">
                    <el-table-column
                        :label='$t("src.views.apps.genel.islem")'
                        align="right"
                        width="100"
                        >
                        <template slot-scope="scope">

                            <el-tooltip content='Cari Güncelle' :open-delay="500" placement="bottom">
                                <el-button @click="updatePage(scope.row)" type="text"
                                    class="islem-button"
                                    style="color: #f7ba2a; margin-left: 0px !important;"
                                    icon="mdi mdi-pen mdi-24px"></el-button> 
                            </el-tooltip>

                        </template>
                    </el-table-column>
                </el-table-column>

            </el-table>
            <div v-if="this.kurumListe.length == []"  style="width: 100%; height: 50px !important; display: flex; justify-content: center; align-items: center">
                    {{$t("src.views.apps.messages.noData")}}
            </div>
            
            <el-pagination
                background
                layout="sizes,prev, pager, next, jumper, total"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="pageChange"
                :page-sizes="[20, 50, 100]"
                :current-page.sync="page"
                :next-click.sync="page"
                :prev-click.sync="page"
                @next-click="pageChange"
                @prev-click="pageChange">
            </el-pagination>

        </div>
    </div>
</template>

<script>
    import kurumService from '../../../WSProvider/KurumService'
    import dosyaService from '../../../WSProvider/DosyaService'
    import EventBus from '@/components/event-bus'
    import notification from '../../../notification'
    import functions from '../../../functions'    
    import jwt_decode from "jwt-decode"

    var moment = require('moment');

    export default {
        name: "CariListesi",
        data() {
            return {
                loading: false,
                stateLoading: false,
                searchLoading: false,
                kurumLoading: false,

                //editDialogLoading: false,
                editDialog:false,
                editDialogData: {},
                
                musteriList: [],
                kurumListe: [],
                
                radio: '1',
                radio1: "1",
                kurumForm: {
                    musteriAdi:"",
                    eposta: "",
                    firmaAdi:"",
                    unvan:"",
                    telefon:""
                },
                rulesMusteri: {},
                
                sizes: 20,
                kurumDurumu: '1',
                page: 0,
                postPage: 0,
                total: 4,
                kurumAdiAra: "",
                yetkiTuru: "",
                unvanAra: "",
                adresAra: "",
                telefonAra: "",
                epostaAra: "", 
                gelenCari: "",
                gidenCari: ""               
            }
        },

        

        mounted() {
            this.yetkiTuru = this.$store.getters.getYetkiTuru 
            console.log(this.yetkiTuru)
            this.getKurumList();
        },

        created(){
            EventBus.$on("cariList", () => {
                this.refreshPage()
            });
        },

        methods: {

            getKurumList() {
                try{
                    this.kurumLoading = true;
                    kurumService.kurumListele(this.kurumDurumu,  this.postPage, this.sizes).then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);
                            response.data.forEach(el => {
                                el = functions.removeSlashesAndDecodeStrings(el)
                            })
                            this.kurumListe = response.data;                            
                            this.total = parseInt(response.count);
                            this.gelenCari = parseInt(response.gelenCari);
                            this.gidenCari = parseInt(response.gidenCari);
                        }
                        this.kurumLoading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.kurumListe = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.kurumLoading = false;
                    })
                }catch(e){
                    this.kurumLoading = false;
                }
            },

            checkDevice(){
                //this.isDesktop = window.innerWidth >= 768;
            },

            updatePage(data) {
                this.$store.commit('changeKurumUpdateData', data.kurumID)
                functions.routeSayfa("Cari Güncelle",this)
            },
            
            kurumAra(){
                this.postPage = 0;
                this.page = 1
                this.dateVisible = false;
                //siparisNo, adSoyad, telefon, country, city, state, baslangicTarihi, bitisTarihi, durum, baslangic, limit,
                if(this.kurumAdiAra.length > 0 ){
                    try{
                        this.searchLoading = true;
                        kurumService.kurumArama(this.kurumAdiAra, this.kurumDurumu, this.postPage, this.sizes).then(response => {
                            if(response.status == 200){
                                this.kurumListe = response.data;
                                this.total = response.count
                            }
                            localStorage.setItem("userDataBGSurec", response.token);
                            this.searchLoading = false;
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }else if(error.status == 404){
                                    this.kurumListe = []
                                }
                            }else{
                                //notification.Status(503, this)
                            }
                            this.searchLoading = false;
                        })
                    }catch(e){
                        // notification.Status("danger", this, "An error occurred during the customer search process.")
                        this.searchLoading = false
                    }
                }else{
                    this.getKurumList();
                }
            },

            refreshPage(){
                this.page = 1;
                this.postPage = 0;
                this.loading = false;
                this.stateLoading = false;
                this.getKurumList();
                
                // this.getMusteriSayisi();
                //this.getMusteriListesi();
                //this.getMusteriProjeListesi();
            },


            vazgec(formName){
                this.$refs[formName].resetFields();
                this.editDialog = false;
                this.loading = false;
                this.stateLoading = false;
            },

            startUpdate(){
                this.kurumForm.firmaAdi = this.editDialogData.firmaAdi;
                this.kurumForm.unvan = this.editDialogData.unvan;
                this.kurumForm.eposta = this.editDialogData.mail;
                this.kurumForm.telefon = this.editDialogData.telefon;
                // this.kurumForm.dogumTarihi = moment(this.editDialogData.dogumTarihi).format("DD-MM-YYYY");
            },

            changeMusteriDurum(selection, durum) {
                this.$confirm("Müşterinin durumunu güncellemek istediğinizden emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
                    confirmButtonText: this.$t("src.views.apps.genel.yes"),
                    cancelButtonText: this.$t("src.views.apps.genel.no"),
                    type: 'warning'
                }).then(() => {
                    try{
                        this.stateLoading = true;
                        var item = { durum : durum }
                        kurumService.musteriGuncelle(selection.musteriID, (item)).then(response => {
                            if(response.status == 200){
                               // this.getMusteriListesi();
                                //this.getMusteriProjeListesi();
                            }
                            this.stateLoading = false;
                            notification.Status("success", this, response.msg);
                            localStorage.setItem("userDataBGSurec", response.token);
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                else notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.stateLoading = false;
                        })
                    }catch(e){
                        // notification.Status("success", this, "An error occurred while fetching the customer number.");
                        this.stateLoading = false;
                    }
                })
            },

                       
            routeKurum(name, scope) {
                if (scope) {
                    this.$store.commit('changeKurumUpdateData', scope);
                }
                functions.routeSayfa(name, this);
            },

            indexMethod(index) {
                return ((this.page - 1)* 10) + index + 1;
            },

            handleSizeChange(val) {
                this.sizes = val;
                this.getKurumList();
                //this.getMusteriListesi();
               // this.getMusteriProjeListesi();
            },

            handleChange(event) {
                this.kurumDurumu = event;
                this.page = 1;
                this.postPage = 0;
                this.getKurumList();
                //this.getMusteriListesi();
                //this.getMusteriProjeListesi();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
                this.kurumAdiAra = ""
                this.unvanAra = ""
                this.adresAra = ""
                this.telefonAra= ""
                this.epostaAra= ""
            },

            deneme(event){
                console.log(event)
            },

            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.getKurumList();
                //this.getMusteriListesi();
                //this.getMusteriProjeListesi();
            },
        }
    }

</script>

<style lang="scss">
    .el-table .info-row {
        background: rgba(125, 199, 225, 0.45);
    }

    .el-table .error-row {
        background: rgba(249, 0, 26, 0.08);
    }

    .el-table .success-row {
        background: rgba(3, 249, 0, 0.08);
    }

    .el-table .warning-row {
        background: rgba(245, 249, 0, 0.08);
    }
</style>
<style lang="scss" scoped>
    .refresh {
        color: #fff;
        float: right;
        padding: 0 !important;
        transition: all 0.5s;

    }

    .refresh:hover {
        transform: rotate(360deg);
    }

    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    .el-radio-button--mini .el-radio-button__inner label {
        position: relative;
        bottom: 5px;
    }

    .kaldirilmis {
        background: #ec205f;
        color: white;
        font-weight: bold;
        font-size: 16px;
        padding: 10px
    }

</style>
<style lang="scss">
    .slide-fade-enter-active {
        transition: all .3s ease;
    }


    @media (min-width: 768px) {
        .el-radio-button--mini .el-radio-button__inner {
            padding: 0 10px !important;
            font-size: 11px;
            border-radius: 0;
        }
    }


    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
<style>
.el-badge__content {
    background-color: red !important;
    color: white;
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    font-weight: 450;
    margin-bottom: -20px !important;
}

.cari .el-badge__content.is-fixed {
    position: absolute;
    top: 0;
    right: 10px;
    -webkit-transform: translateY(-50%) translateX(100%);
    transform: translateY(-50%) translateX(-500%);
}

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #FFFFFF;
    text-align: left;
    /* padding-left: 0; */
    /*background-color: #90c4a1;*/
    background-color: #3b94bd;
    border-color: #3b94bd;
    -webkit-box-shadow: -1px 0 0 0 #3b94bd;
    box-shadow: -1px 0 0 0 #3b94bd;
}


</style>
